<template>
  <div class="p-wrap">
    <!-- 功能区 -->
    <div class="p-header">
      <div class="header-title">
        <div class="title-left">商品信息</div>
        <div class="title-right">
          <span v-role="'RS-M05-F04'" @click="handleEditBatchShowDialog()">
            <img src="@/assets/icon/goodsInfo/insert.png" />批量修改
          </span>
          <span v-role="'RS-M05-F01'" @click="handleShowDialog('insert')">
            <img src="@/assets/icon/goodsInfo/insert.png" />手动添加
          </span>
          <span v-role="'RS-M05-F02'" @click="templateDownVisible = true">
            <img src="@/assets/icon/goodsInfo/download.png" />下载模板
          </span>
          <span v-role="'RS-M05-F02'" @click="importVisible = true">
            <img src="@/assets/icon/goodsInfo/income.png" />批量导入
          </span>
        </div>
      </div>
      <div class="header-select">
        <el-form ref="merchandiseForm" :inline="true" :model="queryParam">
          <el-form-item v-if="!isRetail" label="组织名称">
            <organization-select :model="queryParam"></organization-select>
          </el-form-item>

          <el-form-item v-if="isRetail" label="商品编码">
            <el-input
              clearable
              v-model="queryParam.uniqueCode"
              maxlength="32"
              placeholder="请输入商品编码"
              @keyup.enter.native="handleQuery()"
            ></el-input>
          </el-form-item>

          <el-form-item label="商品名称">
            <el-input
              clearable
              v-model="queryParam.merchandiseName"
              placeholder="请输入商品名称"
              @keyup.enter.native="handleQuery()"
            />
          </el-form-item>

          <el-form-item label="商品状态">
            <el-select
              v-model="queryParam.merchandiseStatus"
              placeholder="请选择商品状态"
              clearable
              filterable
            >
              <el-option
                v-for="item in dictionary.merchandiseStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="商品类型" v-show="queryExpended">
            <el-select
              clearable
              v-model="queryParam.merchandiseCategory"
              placeholder="请选择商品类型"
            >
              <el-option
                v-for="item in MerchandiseCategory"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="税分名称" v-show="queryExpended">
            <el-input
              clearable
              v-model="queryParam.taxClassName"
              placeholder="请输入税分名称"
              @keyup.enter.native="handleQuery()"
            />
          </el-form-item>

          <el-form-item label="确认状态" v-show="queryExpended">
            <el-select clearable v-model="queryParam.confirmStatus">
              <el-option
                v-for="item in confirmStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="赋码方式" v-show="queryExpended">
            <el-select clearable v-model="queryParam.codeType">
              <el-option
                v-for="item in merchandiseCodeTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="更新时间" v-show="queryExpended">
            <el-date-picker
              clearable
              v-model="queryParam.updateTime"
              type="month"
              value-format="yyyyMM"
              :picker-options="endDateOption"
              style="width: 200px"
              placeholder="请选择更新时间"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="特殊税率" v-show="queryExpended">
            <el-select clearable v-model="queryParam.taxPreferentialContent">
              <el-option
                v-for="item in taxPreferential"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="商品税率" v-show="queryExpended">
            <el-select clearable v-model="queryParam.taxRate">
              <el-option
                v-for="item in taxRateData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="margin-left: 32px">
            <select-button
              show-status
              @list-close="handleListClose"
              @list-show="handleListShow"
              @select="handleQuery"
              @reset="handleReset"
            ></select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="p-content">
      <div class="main-top">
        <div class="top-left">
          <span>商品列表</span>
          <el-popover placement="right" width="80" trigger="click">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选
            </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group
              v-model="colOptions"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in colSelect"
                :label="item"
                :key="item"
                style="display: block; margin-top: 6px"
              ></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="rowSet">
              <img class="imgSet" src="@/assets/icon/set.png" />
              <span>列设置</span>
            </div>
          </el-popover>
        </div>
        <div>
          <el-button type="primary" @click="handleConfirmBatch"
            >批量确认</el-button
          >
          <el-button
            type="success"
            :disabled="merchandiseCodingLoading"
            :loading="merchandiseCodingLoading"
            @click="handleCodingMerchandise"
            >智能赋码</el-button
          >
          <el-button type="danger" @click="handleInvoiceBatchDelete"
            >批量删除</el-button
          >
          <el-button type="warning" @click="versionVisible = true"
            >税收版本</el-button
          >
          <el-button type="default" @click="handleExportBilling"
            >导出</el-button
          >
        </div>
      </div>
      <div class="p-table">
        <el-table
          :data="records"
          border
          stripe
          v-loading="tableLoading"
          ref="merchandiseInfoTable"
          @selection-change="handleSelection"
          :header-cell-style="handleHeaderCellStyle"
        >
          <el-table-column type="selection" width="50" fixed="left" />
          <el-table-column
            type="index"
            label="序号"
            width="60"
            fixed="left"
          ></el-table-column>
          <el-table-column
            v-if="colData[0] && colData[0].isTrue"
            prop="uniqueCode"
            label="商品编码"
            min-width="150"
            fixed="left"
          ></el-table-column>
          <el-table-column
            v-if="colData[1] && colData[1].isTrue"
            prop="merchandiseStatus"
            label="商品状态"
            width="100"
            header-align="center"
            align="center"
            fixed="left"
            :formatter="formatMerchandiseStatus"
          ></el-table-column>
          <el-table-column
            v-if="colData[2] && colData[2].isTrue"
            prop="merchandiseName"
            label="商品名称"
            show-overflow-tooltip
            min-width="250"
          ></el-table-column>
          <el-table-column
            v-if="colData[3] && colData[3].isTrue"
            prop="taxRate"
            label="税率(一般纳税人)"
            width="80"
            align="right"
            :formatter="fmtColumn"
          ></el-table-column>
          <el-table-column
            v-if="colData[4] && colData[4].isTrue"
            prop="ssTaxRate"
            label="税率(小规模纳税人)"
            width="80"
            align="right"
            :formatter="fmtColumn"
          ></el-table-column>
          <el-table-column
            v-if="colData[5] && colData[5].isTrue"
            prop="taxPreferentialContent"
            label="是否特殊税率"
            width="100"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.taxPreferentialContent === ""
                  ? "否"
                  : scope.row.taxPreferentialContent
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[6] && colData[6].isTrue"
            prop="price"
            label="单价"
            width="100"
            align="right"
            :formatter="fmtColumn"
          ></el-table-column>
          <el-table-column
            v-if="colData[7] && colData[7].isTrue"
            prop="taxClassCode"
            min-width="200"
            label="税收分类编码"
          ></el-table-column>
          <el-table-column
            v-if="colData[8] && colData[8].isTrue"
            prop="taxClassName"
            label="税收分类名称"
            show-overflow-tooltip
            min-width="140"
          ></el-table-column>
          <el-table-column
            v-if="colData[9] && colData[9].isTrue"
            prop="unit"
            label="单位"
            min-width="100"
          ></el-table-column>
          <el-table-column
            v-if="colData[10] && colData[10].isTrue"
            prop="merchandiseCategory"
            label="商品类型"
            header-align="center"
            align="center"
            width="100"
            :formatter="fmtColumn"
          ></el-table-column>
          <el-table-column
            v-if="colData[11] && colData[11].isTrue"
            prop="specification"
            label="规格型号"
            min-width="100"
          ></el-table-column>
          <el-table-column
            v-if="colData[12] && colData[12].isTrue"
            prop="name"
            label="所属组织"
            min-width="180"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="colData[13] && colData[13].isTrue"
            prop="confirmStatus"
            label="确认状态"
            width="100"
            :formatter="fmtColumn"
          ></el-table-column>
          <el-table-column
            v-if="colData[14] && colData[14].isTrue"
            prop="created"
            label="创建时间"
            width="180"
            :formatter="fmtColumn"
          />
          <el-table-column
            v-if="colData[15] && colData[15].isTrue"
            prop="updated"
            label="更新时间"
            width="180"
            :formatter="fmtColumn"
          />
          <el-table-column
            v-if="colData[16] && colData[16].isTrue"
            prop="codeType"
            label="赋码方式"
            width="100"
            :formatter="fmtColumn"
          />
          <el-table-column
            v-if="colData[17] && colData[17].isTrue"
            prop="dataFrom"
            label="数据来源"
            width="100"
            :formatter="fmtColumn"
          />
          <el-table-column
            v-if="colData[18] && colData[18].isTrue"
            prop="operate"
            label="操作"
            width="180"
            fixed="right"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <!-- <div class="operate-button" style="width: 200px"> -->
              <el-button
                type="text"
                size="medium"
                style="padding: 0"
                @click="handleShowDialog('update', scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="medium"
                style="padding: 0"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-button
                v-role="'UPDATE_ALL_MERCHANDISE'"
                type="text"
                size="medium"
                style="padding: 0"
                @click="handleShowDialog('updateAll', scope.row)"
                >编辑全部</el-button
              >
              <!-- </div> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="records.length > 0" class="p-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleQuery"
          :current-page.sync="queryParam.page"
          :page-sizes="[15, 30, 50, 100]"
          :page-size="queryParam.size"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      append-to-body
      :title="editTitle"
      :visible.sync="editVisible"
      :show-close="false"
    >
      <merchandise-edit
        v-if="editVisible"
        :merchandise="merchandise"
        :is-retail="isRetail"
        ref="merchandiseForm"
        :content="taxPreferentialContents"
        @handleCancelEditDialog="handleCancelEditDialog"
      ></merchandise-edit>
    </el-dialog>
    <el-dialog
      append-to-body
      :title="editBatchTitle"
      :visible.sync="editBatchVisible"
    >
      <merchandise-edit-batch
        :ids="ids"
        ref="merchandiseEditForm"
        :content="taxPreferentialContents"
        @handleCancelEditDialog="handleCancelEditBatchDialog"
      ></merchandise-edit-batch>
    </el-dialog>
    <el-dialog
      append-to-body
      title="批量修改税收版本信息"
      :visible.sync="versionVisible"
      width="500px"
    >
      <div style="display: flex; padding-bottom: 40px">
        <el-form label-width="120px">
          <el-form-item label="版本号：">
            <el-input v-model="versionInfo.taxVersionNo"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 10px">
            <span style="color: red">注：商品税收分类版本号！</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="default" @click="versionVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleSaveGoodsVersion"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      title="模板下载"
      :visible.sync="templateDownVisible"
      width="500px"
    >
      <merchandise-template-download></merchandise-template-download>
    </el-dialog>
    <el-dialog
      append-to-body
      title="导入商品"
      :visible.sync="importVisible"
      width="500px"
      :show-close="false"
    >
      <merchandise-import
        @handleCancelImportDialog="importVisible = false"
      ></merchandise-import>
    </el-dialog>
    <!-- 批量删除 -->
    <el-dialog
      append-to-body
      width="20%"
      :visible.sync="invoiceBatchDeleteVisible"
    >
      <div class="dialog-delete">
        <span>确认删除选择商品?</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelDelete">取消</el-button>
        <el-button type="primary" @click="confirmDelete">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle } from "@/util/mixins";
import {
  pagingMerchandise,
  updateTaxVersion,
  deleteById,
  deleteMerchandiseByIds,
  confirmBatch,
  handleExportExcel,
  getById,
  codingMerchandise,
} from "@/service/merchandise.js";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import MerchandiseTemplateDownload from "./MerchandiseTemplateDownload";
import MerchandiseImport from "./MerchandiseImport";
import MerchandiseEdit from "./MerchandiseEdit";
import MerchandiseEditBatch from "./MerchandiseEditBatch";
import { MERCHANDISE_INFO_LIST } from "@/content/merchandiseInfo";

export default {
  name: "MerchandiseList",
  components: {
    OrganizationSelect,
    MerchandiseTemplateDownload,
    MerchandiseImport,
    MerchandiseEdit,
    MerchandiseEditBatch,
  },
  mixins: [tableStyle],
  data() {
    return {
      queryExpended: false,

      dictionary: {
        merchandiseStatus: [],
      },

      merchandiseCodingLoading: false,

      colData: MERCHANDISE_INFO_LIST,
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      selections: [], //选择行
      colSelect: MERCHANDISE_INFO_LIST.map((i) => i.title),
      isRetail: this.$store.state.user.industry == "RETAIL",
      // 筛选条件
      queryParam: {
        orgId: 0,
        uniqueCode: undefined,
        merchandiseName: undefined,
        merchandiseStatus: undefined,
        taxClassName: undefined,
        merchandiseCategory: undefined,
        taxPreferentialContent: undefined,
        confirmStatus: undefined,
        largeKind: undefined,
        codeType: undefined,
        updateTime: undefined,
        page: 1,
        size: 15,
      },
      endDateOption: {
        disabledDate(time) {
          if (new Date().getFullYear() === time.getFullYear()) {
            return time.getMonth() >= new Date().getMonth() + 1;
          }
        },
      },
      merchandise: {
        orgId: Number(localStorage.getItem("orgId")),
        uniqueCode: undefined,
        merchandiseName: "",
      },
      ids: [],
      // 版本号信息
      versionInfo: {
        orgId: "",
        taxVersionNo: "",
      },
      MerchandiseCategory: [...this.$store.state.enums["MerchandiseCategory"]],
      tableLoading: false, // 表格加载loading
      versionVisible: false, // 版本号弹窗
      templateDownVisible: false, // 模版下载弹窗
      importVisible: false, // 导入弹窗
      editVisible: false, // 编辑弹窗
      editTitle: "", // 编辑弹窗标题
      editBatchVisible: false,
      editBatchTitle: "",
      records: [],
      total: 0, // 数据总数count
      serialNo: "", //商品编码
      taxPreferentialContents: [],
      merchandiseCodeTypes: [],
      confirmStatuses: [],
      dataFroms: [],
      invoiceBatchDeleteVisible: false,
      taxPreferential: [
        {
          value: "免税",
          label: "免税",
        },
        {
          value: "不征税",
          label: "不征税",
        },
        {
          value: "超税负3%即征即退",
          label: "超税负3%即征即退",
        },
      ],
      taxRateData: [
        {
          value: 0.0,
          label: "0%",
        },
        {
          value: 0.01,
          label: "1%",
        },
        {
          value: 0.03,
          label: "3%",
        },
        {
          value: 0.04,
          label: "4%",
        },
        {
          value: 0.05,
          label: "5%",
        },
        {
          value: 0.06,
          label: "6%",
        },
        {
          value: 0.09,
          label: "9%",
        },
        {
          value: 0.1,
          label: "10%",
        },
        {
          value: 0.11,
          label: "11%",
        },
        {
          value: 0.13,
          label: "13%",
        },
        {
          value: 0.16,
          label: "16%",
        },
        {
          value: 0.17,
          label: "17%",
        },
      ],
    };
  },
  created() {
    this.initDict();
    this.handleQuery();
    this.handleGetLineSet();
  },
  watch: {
    colOptions(valArr) {
      const arr = this.colSelect.filter((i) => valArr.indexOf(i) < 0); // 未选中
      this.colData.filter((i) => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false;
          this.$nextTick(() => {
            this.$refs.merchandiseInfoTable.doLayout();
          });
        } else {
          i.isTrue = true;
          this.$nextTick(() => {
            this.$refs.merchandiseInfoTable.doLayout();
          });
        }
      });
      localStorage.setItem("merchandiseInfo", JSON.stringify(this.colData));
    },
  },
  methods: {
    // 收起筛选列表
    handleListClose() {
      this.queryExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.queryExpended = true;
    },
    // 重置查询条件
    handleReset() {
      this.queryParam = {
        orgId: 0,
        uniqueCode: undefined,
        merchandiseStatus: [],
        merchandiseName: undefined,
        taxClassName: undefined,
        merchandiseCategory: undefined,
        taxPreferentialContent: undefined,
        confirmStatus: undefined,
        largeKind: undefined,
        codeType: undefined,
        updateTime: undefined,
        page: 1,
        size: 15,
      };
    },

    // 初始化字典
    async initDict() {
      const { success, data } = await this.batchGetDicts({
        types: [
          "o-merchandise-tax",
          "m_code_type",
          "m_confirm_status",
          "m_data_from",
          "sal_merchandise_status",
        ],
      });
      if (success) {
        this.taxPreferentialContents = data["o-merchandise-tax"];
        this.merchandiseCodeTypes = data["m_code_type"];
        this.confirmStatuses = data["m_confirm_status"];
        this.dataFroms = data["m_data_from"];
        this.dictionary.merchandiseStatus = data["sal_merchandise_status"];
      }
    },
    // 弹窗控制
    handleShowDialog(type, val) {
      if (type == "updateAll") {
        val.forbid = "";
      }
      this.editTitle = type == "insert" ? "添加商品信息" : "编辑商品信息";
      this.editVisible = true;
      if (val) {
        if (
          val.taxPreferentialContent == "免税" ||
          val.taxPreferentialContent == "不征税"
        ) {
          val.taxRateModel = val.taxPreferentialContent;
          val.ssTaxRateModel = val.taxPreferentialContent;
        } else {
          val.taxRateModel = val.taxRate;
          val.ssTaxRateModel = val.ssTaxRate;
        }
        this.merchandise = JSON.parse(JSON.stringify(val));
        if (!this.merchandise.parentId) return;
        getById(this.merchandise.parentId).then(({ success, data }) => {
          if (success) this.merchandise.middleKind = data.merchandiseName;
        });
      } else {
        this.merchandise = {
          orgId: Number(localStorage.getItem("orgId")),
          uniqueCode: undefined,
          largeKind: undefined,
          parentId: null,
          taxRate: null,
          middleKind: undefined,
          taxRateModel: null,
          taxClassCode: undefined,
          taxClassName: undefined,
          isTaxPreferential: "0",
          priceVariable: "1",
          taxPreferentialContent: "",
        };
      }
    },
    handleEditBatchShowDialog() {
      if (this.selections == null || this.selections.length <= 0) {
        this.toast("请选择商品信息", "warning");
        return;
      }
      this.editBatchTitle = "批量编辑商品";
      this.editBatchVisible = true;
      this.ids = this.selections.map((item) => item.id);
    },
    // 查询
    handleQuery(val) {
      this.queryParam.page = val ? val : 1;
      this.doPagingMerchandise();
    },
    doPagingMerchandise() {
      this.tableLoading = true;
      pagingMerchandise(this.queryParam).then((res) => {
        this.tableLoading = false;
        if (res.success) {
          this.records = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.queryParam.size = val;
      this.queryParam.page = 1;
      this.doPagingMerchandise();
    },
    // 删除商品
    async handleDelete(row) {
      const { success } = await deleteById(row.id);
      if (success)
        this.toast("删除商品信息成功", "success", () =>
          this.doPagingMerchandise()
        );
    },
    // 关闭商品dialog
    handleCancelEditDialog(status) {
      this.editVisible = false;
      if (status == "success") {
        this.doPagingMerchandise();
      }
      this.$refs.merchandiseForm.resetForm();
    },
    handleCancelEditBatchDialog(status) {
      this.editBatchVisible = false;
      if (status == "success") {
        this.doPagingMerchandise();
      }
      this.$refs.merchandiseEditForm.resetForm();
    },
    // 保存版本号
    async handleSaveGoodsVersion() {
      const { success } = await updateTaxVersion(this.versionInfo);
      if (success)
        this.toast("商品版本号修改成功", "success", () => {
          this.versionVisible = false;
          this.doPagingMerchandise();
        });
    },
    formatMerchandiseStatus(row, column, value) {
      return this.getDictLabel(this.dictionary.merchandiseStatus, value);
    },
    // 格式化表格列
    fmtColumn(row, column) {
      switch (column.property) {
        case "taxRate":
          if (
            row.taxPreferentialContent == "免税" ||
            row.taxPreferentialContent == "不征税"
          ) {
            return row.taxPreferentialContent;
          }
          return row.taxRate * 100 + "%";
        case "ssTaxRate":
          if (
            row.taxPreferentialContent == "免税" ||
            row.taxPreferentialContent == "不征税"
          ) {
            return row.taxPreferentialContent;
          }
          return row.ssTaxRate * 100 + "%";
        case "price":
          return row.price ? "¥" + row.price : "";
        case "merchandiseCategory":
          return this.handleValueToLabel(
            "MerchandiseCategory",
            row.merchandiseCategory
          );
        case "codeType":
          return this.getDictLabel(this.merchandiseCodeTypes, row.codeType);
        case "confirmStatus":
          return this.getDictLabel(this.confirmStatuses, row.confirmStatus);
        case "dataFrom":
          return this.getDictLabel(this.dataFroms, row.dataFrom);
        case "updated":
          return this.$moment(row.updated).format("YYYY-MM-DD HH:mm:ss");
        case "created":
          return this.$moment(row.created).format("YYYY-MM-DD HH:mm:ss");
        default:
          return "";
      }
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
    },
    //获取列设置
    handleGetLineSet() {
      if (JSON.parse(localStorage.getItem("merchandiseInfo")) != null) {
        this.colData = JSON.parse(localStorage.getItem("merchandiseInfo"));
        this.colData.push(...MERCHANDISE_INFO_LIST);
        this.colData = this.uniqueBy(this.colData, (i) => i.title);
        if (
          this.colData.filter((i) => i.isTrue == true).length ===
          this.colSelect.length
        ) {
          this.checkAll = true;
          this.isIndeterminate = false;
        }
        this.colOptions = JSON.parse(localStorage.getItem("merchandiseInfo"))
          .filter((i) => i.isTrue === true)
          .map((item) => item.title); //选中
      } else {
        this.colOptions = this.colData.map((item) => item.title); //默认所有
        this.checkAll = true;
        this.isIndeterminate = false;
      }
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect;
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    //列设置改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colSelect.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.colSelect.length;
    },
    //导出excel
    async handleExportBilling() {
      if (this.selections.length == 0) {
        this.toast("请勾选需要导出的商品", "warning");
        return;
      }
      let items = this.selections;
      this.exportLoading = true;
      let ids = items.map((item) => item.id);
      const rsp = await handleExportExcel(ids);
      this.exportLoading = false;
      this.downloadFile(rsp);
    },
    handleCodingMerchandise() {
      this.merchandiseCodingLoading = true;
      let codingParam = JSON.parse(JSON.stringify(this.queryParam));
      codingParam.ids = this.selections.map((i) => i.id);
      codingMerchandise(codingParam).then((res) => {
        this.merchandiseCodingLoading = false;
        if (res.success) {
          this.toast("智能赋码请求已提交！", "success");
        }
      });
    },
    /* 发票删除弹窗 */
    handleInvoiceBatchDelete() {
      if (this.selections.length == 0) {
        this.toast("请勾选商品信息！", "warning");
        return;
      }
      this.invoiceBatchDeleteVisible = true;
    },
    /* 批量删除选中票据 */
    async confirmDelete() {
      let ids = this.selections.map((i) => i.id);
      const { success } = await deleteMerchandiseByIds(ids);
      if (success) {
        this.toast("批量删除成功！", "success", () => {
          this.invoiceBatchDeleteVisible = false;
          this.doPagingMerchandise();
          this.cancelDelete();
        });
      } else {
        this.toast("批量删除失败！", "error");
      }
    },
    // 批量确认税收分类编码
    async handleConfirmBatch() {
      if (this.selections.length < 1)
        return this.toast("未选中数据", "warning");

      const index = this.selections.findIndex((i) => i.confirmStatus == "1");
      if (index > -1)
        return this.toast("当前勾选列表存在已确认商品行", "warning");

      const { success, message } = await confirmBatch({
        ids: this.selections.map((i) => i.id),
      });
      if (success)
        this.toast("商品批量确认成功", "success", () =>
          this.doPagingMerchandise()
        );
      else this.toast(message, "error");
    },
    /* 取消选中 */
    cancelDelete() {
      this.selections = [];
      this.invoiceBatchDeleteVisible = false;
      this.$refs.merchandiseInfoTable.clearSelection();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.el-form-item {
  margin-bottom: 20px;
}
.p-header {
  .header-title {
    display: flex;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .title-right {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;

        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}
</style>
